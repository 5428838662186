.back-to-top-btn {
  position: fixed;
  bottom: 60px;
  right: 60px;
  width: 60px;
  height: 60px;
  font-size: 1.5rem;

  @media(width < 600px) {
    bottom: 30px;
    right: 30px;
    width: 50px;
    height: 50px;
    font-size: 1.2rem;
  }

  background-color: var(--color-secondary);
  border: 1px solid var(--color-border);
  border-radius: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  color: var(--color-text);

  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: all 0.15s ease-out;
  transform: translateY(5px);

  &.show {
    visibility: visible;
    opacity: 1;
    pointer-events: all;
    transform: none;
  }

  &:hover {
    transform: scale(1.05);
    box-shadow: 0px 0px 10px -2px var(--color-primary);
  }
}
