.technology-chip {
  --chip-color: 255 255 255;

  border-radius: 100px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  gap: 4px;
  width: fit-content;
  border: 1px solid rgb(var(--chip-color) / 0.3);
  background-color: rgb(var(--chip-color) / 0.15);
  color: var(--color-text);

  & img {
    width: 16px;
    height: 16px;
  }
}
