:root {
  --color-background: #151416;
  --color-accent: #5875AF;
  --color-primary: #947DC2;
  --color-secondary: #19181B;
  --color-border: #7E7E7E1A;
  --color-text: #FEFBFB;
  --color-text-secondary: #A0A0A0;
  --color-tonal-button: #3F3F3F;
  color-scheme: dark;

  --elevation-box-shadow:
    0px 6px 10px 4px rgba(0, 0, 0, 15%),
    0px 2px 3px 0px rgba(0, 0, 0, 30%);
}

* {
  font-family: "Inter", sans-serif;
  margin: 0;
  padding: 0;
}


body {
  position: relative;
  background-color: var(--color-background);
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main#main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.page-margins {
  width: min(1200px, 95%);
}

.standard-section {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 100px 0;
}


/* Text styles */
.uppercase {
  text-transform: uppercase;
}

h1 {
  font-weight: 700;
  font-size: 3rem;
  line-height: 120%;
}

h2 {
  font-size: 2rem;
  font-weight: 600;
  line-height: 120%;
}

.subheading {
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 120%;
}

.headline-medium {
  font-size: 1.75rem;
  line-height: 2.25rem;
}

.title-small {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.title-medium {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
}

.body-strong {
  font-size: 1rem;
  line-height: 140%;
  font-weight: 600;
}

.body-base {
  font-size: 1rem;
  line-height: 140%;
}

.body-medium {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.body-small {
  font-size: 0.75rem;
  line-height: 1rem;
}

.color-text-secondary {
  color: var(--color-text-secondary);
}

.color-text {
  color: var(--color-text);
}

.color-primary {
  color: var(--color-primary);
}

& .headed-info {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

/* Inputs */
.padded-btn {
  --static-btn-color: var(--color-primary);

  &.tonal {
    --static-btn-color: var(--color-tonal-button);
  }


  padding: 0.7em 1.8em;
  font-size: 0.875;
  line-height: 20px;

  &.small {
    font-size: 0.75rem;
  }

  font-weight: 500;
  border-radius: 4px;
  width: fit-content;
  background-color: var(--static-btn-color);
  color: var(--color-text);
  text-decoration: none;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;

  transition: all 0.3s ease-out;

  background-image: var(--bg-image);
  position: relative;

  --bg-image: var(--static-btn-color);
  &.gradient {
    --bg-image: linear-gradient(
      90deg,
      var(--color-primary),
      var(--color-accent));
  }

  &.disabled {
    pointer-events: none;

    &::before {
      content: '';
      inset: 0;
      position: absolute;
      border-radius: inherit;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  &:not(.disabled) {
    &:before {
      content: '';
      opacity: 0;
      position: absolute;
      inset: 0;
      border-radius: inherit;
      padding: 2px;
      background: var(--bg-image);
      -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      transition: all 0.3s ease-out;
    }

    &:hover {
      background: transparent;
      /* color: var(--color-accent); */

      &:before {
        opacity: 1;
      }
    }
  }


  &.pilled {
    border-radius: 100px;
  }
}


.input-button, .input-select {
  width: 260px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: 1px solid var(--color-border);
  background-color: transparent;
  color: var(--color-text-secondary);
  cursor: pointer;
  transition: background-color 0.15s ease-out;

  &:hover {
    background-color: rgba(255, 255, 255, 0.03);
  }
}

.input-select {
  padding: 0 16px;
  background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23A0A0A0' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;

  background-color: var(--color-secondary);

  & option {
    background-color: var(--color-secondary)
  }
}


/* Remove default checkbox appearance */
.input-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid var(--color-border);
  border-radius: 4px;
  outline: none;
  cursor: pointer;
}

/* Style the checked state */
.input-checkbox:checked {
  background-color: transparent;
  border-color: var(--color-primary);
  position: relative;
}

/* Add a checkmark using ::after pseudo-element */
.input-checkbox:checked::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  background-color: var(--color-primary);
  transform: translate(-50%, -50%);
  border-radius: 2px;
}
