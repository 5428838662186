.project-page {
  display: flex;
  flex-direction: column;
  align-items: center;

  & .project-intro-wrapper {
    position: relative;
    height: 570px;
    width: 100%;
    /* background-size: cover;
    background-position: bottom center; */

    display: flex;
    justify-content: center;

    --background-image: none;

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      background-image: var(--background-image);
      background-size: cover;
      background-position: top center;
      z-index: -1;
      filter: blur(8px);
      opacity: 0.1;
    }

    & .project-intro-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 32px;

      & .text {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      & .buttons {
        display: flex;
        gap: 10px;
      }
    }
  }

  & .project-images-section {
    & .project-images-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
      border-radius: 8px;
      overflow: hidden;

      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      @media (width < 600px) {
        grid-template-columns: 1fr;
      }

      @media (width >= 600px) {
        & img:nth-child(1) {
          grid-column: 1 / 3;
        }

        & img:nth-child(2) {
          grid-row: 2 / 4;
        }
      }
    }
  }
}
