#footer {
  display: flex;
  justify-content: center;
  border-top: 1px solid var(--color-border);
  background-color: var(--color-background);
  margin-top: auto;

  & .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    padding: 50px;
  }

  & .branding-link img {
    height: 46px;
    width: auto;
  }

  & .links {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    & .socials {
      display: flex;
      gap: 16px;

      & a {
        display: flex;
        align-items: center;
        justify-content: center;
        /* line-height: 1em; */
        height: 30px;
        width: 30px;
        border-radius: 100%;
        padding: 6px;
        background-color: var(--color-secondary);
        color: var(--color-text-secondary);

        transition:
          background-color 0.15s ease-out,
          color 0.15s ease-out;

        &:hover {
          background-color: var(--color-primary);
          color: var(--color-text);
        }
      }
    }
  }
}