#navbar {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid var(--color-border);
  background-color: var(--color-background);

  & .nav-wrapper {
    background-position-y: top;
    background-position: top center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 120px;
    width: min(1200px, 95%);
  }
}

#navbar .branding-link {
  transition: transform 0.15s ease-out;

  &:hover {
    transform: scale(1.03) translateY(-4px);
  }

  img {
    height: 46px;
    width: auto;
    &.small {
      display: none;
    }
  }
}


#navbar .navlinks {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  /* height: 1.6rem; */

  /* underline */
  --underline-position: 0;
  --navlink-width: 120px;

  &::after {
    content: "";
    display: block;
    position: absolute;
    width: var(--navlink-width);
    height: 1px;
    bottom: 0;
    left: calc(var(--underline-position) * var(--navlink-width));
    /* background-color: var(--color-text); */
    background-color: var(--color-primary);
    transition: left 0.15s ease-in;
  }

  & li {
    &.active a,
    &:hover a {
      color: var(--color-primary);
    }

    & a {
      display: block;
      color: var(--color-text);
      text-decoration: none;
      font-size: 1rem;
      width: var(--navlink-width);
      text-align: center;
      line-height: 2.6rem;
    }
  }
}


@media (width < 800px) {
  #navbar .nav-wrapper {
    flex-direction: column;
    height: auto;
    padding: 30px 0;
  }

  #navbar .navlinks {
    --navlink-width: 74px;
  }

  /* #navbar .branding-link img {
    &.small {
      display: block;
    }

    &.full {
      display: none;
    }
  } */
}
