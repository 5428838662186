.project-cards-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  /* flex-wrap: wrap; */
  gap: 20px;
  width: 100%;
}

.project-card {
  border: 1px solid var(--color-border);
  border-radius: 8px;
  background-image: linear-gradient(
    45deg,
    var(--color-secondary),
    #1F1D21
  );
  /* min-width: 300px; */
  /* max-width: 500px; */
  height: 480px;
  display: flex;
  flex-direction: column;
  box-shadow: var(--elevation-box-shadow);
  transition:
    transform 0.15s ease-out,
    box-shadow 0.15s ease-out;

  & * {
    text-decoration: none;
  }

  &:hover {
    /* transform: scale(1.02); */
    transform: scale(1.02) rotateX(-5deg) translateY(-6px);
    box-shadow: 0px 16px 32px -8px rgba(12, 12, 13, 40%);

    & .content {
      & .cover-img{
        transform: scale(1.1);
    }}
  }

  & .content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-grow: 1;

    & .cover-img-wrapper {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      width: 100%;
      height: 200px;
      overflow: hidden;

      & .cover-img {
        transition: transform 0.15s ease-out;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    & .info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;

      & .text {
        & h3 {
          color: var(--color-text);
          margin-bottom: 4px;
        }
      }

      & .tech-chips {
        display: flex;
        flex-wrap: wrap;
        gap: 6px;
      }
    }
  }

  & .buttons {
    display: flex;
    gap: 10px;
    padding: 14px 20px;
    background-color: rgba(255, 255, 255, 0.03);

    & a {
      width: 50%;
    }
  }
}