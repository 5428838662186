.tools-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  & .tool-chip {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 12px 14px;
    border-radius: 8px;
    background-color: var(--color-secondary);
    border: 1px solid var(--color-border);
    box-shadow: inset 0px 0px 10.899999618530273px 1px
      rgba(255, 255, 255, 5%);

    & img {
      width: 24px;
      height: 24px;
    }
  }
}
