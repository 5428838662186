.homepage-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.background-blur-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  overflow: hidden;
  z-index: -1;
  /* max-height: 100vh; */

  display: flex;
  flex-direction: column;
  align-items: center;

  pointer-events: none;

  & .background-blur {
    width: max(1920px, 100%);
    /* max-height: 100vh; */
    object-fit: cover;
  }
}
